// 导出列表
const exportListURL = `${API_CONFIG.butlerBaseURL}landInfo/exportLandInfoList`

// 获取土地列表
const getLandList = `${API_CONFIG.butlerBaseURL}landInfo/getLandInfoListByPage`
// 土地详情
const getLandInfo = `${API_CONFIG.butlerBaseURL}landInfo/detailsLandInfo`
// 土地编辑
const submitUrl = `${API_CONFIG.butlerBaseURL}landInfo/addLandInfo`
// 土地信息删除
const deleteLand = `${API_CONFIG.butlerBaseURL}landInfo/deleteLandInfo`

const uploadImageURL = `${API_CONFIG.uploadURL}?module=user`

export {
  exportListURL,

  getLandList,
  getLandInfo,
  submitUrl,
  deleteLand,
  uploadImageURL
}
