<template>
  <div class="landManageList-wrapper">
    <list
      ref="list"
      exportPermission="export"
      :searchUrl="searchUrl"
      :exportUrl="exportUrl"
      :searchParams.sync="searchParams"
      :headers="headers"
    >
      <template #headerSlot>
        <!-- <v-button text="导入" permission="import" @click="landImport"></v-button> -->
        <v-button text="新增" permission="add" @click="create"></v-button>
      </template>
      <template #searchSlot>
        <v-select2 label="所属项目" :width="180" v-model="searchParams.communityId" v-bind="communityParams"></v-select2>
        <v-input :width="180" label="地块代码" v-model="searchParams.blockOfCode"></v-input>
        <v-input :width="180" label="地块名称" v-model="searchParams.siteName"></v-input>
        <v-select :width="180" label="地块类型" v-model="searchParams.plotCategory" :options="landType"></v-select>
        <v-select :width="180" label="确权状态" v-model="searchParams.authenticRight" :options="RightConfirStatus"></v-select>
        <v-input :width="180"  label="户主" v-model="searchParams.userName"></v-input>
        <v-input :width="180" label="承包家庭" v-model="searchParams.houseAddress"></v-input>
        <v-datepicker label="创建时间" :startTime.sync="searchParams.intimeStart" :endTime.sync="searchParams.intimeEnd"></v-datepicker>
      </template>
      <template #operateSlot="scope">
        <v-button text="查看" type="text" permission="update" @click="check(scope.row)"></v-button>
        <v-button text="编辑" type="text" permission="update" @click="edit(scope.row)"></v-button>
        <v-button  :disabled="scope.row.authenticRight !== 0" text="删除" type="text" permission="update" @click="del(scope.row)"></v-button>
      </template>
    </list>
  </div>
</template>

<script>
import {
  getLandList,
  deleteLand,
  exportListURL,
} from './api'
import {
  landType,
  landTypeMap,
  RightConfirStatus
} from './map'
import { communityParams } from 'common/select2Params'

export default {
  name: 'OwnerCarList',
  data () {
    return {
      landType,
      RightConfirStatus,

      searchUrl: getLandList,
      exportUrl: exportListURL,
      communityParams,
      searchParams: {
        intimeStart: '', // 创建开始时间
        intimeEnd: '', // 创建结束时间
        blockOfCode: '', // 地块代码
        siteName: '', // 地块名称
        plotCategory: undefined, // 地块类型
        authenticRight: undefined, // 确权状态
        communityId: '', // 项目ID
        userName: '', // 户主
        houseAddress: '', // 承包家庭
      },
      headers: [
        {
          prop: 'communityName',
          label: '所属项目'
        },
        {
          prop: 'blockOfCode',
          label: '地块代码'
        },
        {
          prop: 'siteName',
          label: '地块名称'
        },
        {
          prop: 'plotCategory',
          label: '地块类型',
          formatter: row => landTypeMap[row.plotCategory]
        },
        {
          prop: 'madeByArea',
          label: '面积',
          formatter: row => {
            const { madeByAreaHectare, madeByAreaLi, madeByAreaMu, madeByAreaHao, madeByAreaFen} = row
            const Hectare = Number(madeByAreaHectare) !== 0 ? `${Number(madeByAreaHectare)}公顷` : ''
            const Li = Number(madeByAreaLi) !== 0 ? `${Number(madeByAreaLi)}厘` : ''
            const Mu = Number(madeByAreaMu) !== 0 ? `${Number(madeByAreaMu)}亩` : ''
            const Hao = Number(madeByAreaHao) !== 0 ? `${Number(madeByAreaHao)}毫` : ''
            const Fen = Number(madeByAreaFen) !== 0 ? `${Number(madeByAreaFen)}分` : ''
            return Hectare + Mu + Fen + Li + Hao
          }
        },
        {
          prop: 'beLocatedList',
          label: '坐落（四至）',
          formatter: row => {
            const [east, west, south, north]  = row.beLocatedList
            return `东至${east}, 西至${west}, 南至${south}, 北至${north}`
          }
        },
        {
          prop: 'authenticRight',
          label: '确权状态',
          formatter: row => row.authenticRight === 0 ? '未确权' : '已确权'
        },
        {
          prop: 'houseAddress',
          label: '承包家庭'
        },
        {
          prop: 'userName',
          label: '户主'
        },
        {
          prop: 'intime',
          label: '创建时间'
        },
        {
          prop: 'updateTime',
          label: '修改时间'
        },
        {
          prop: 'updateUser',
          label: '操作人'
        }
      ]
    }
  },
  methods: {
    // 新增
    create () {
      this.$router.push({
        name: 'landManageForm',
        query: {
          type: 'add'
        }
      })
    },
    /**
     * @description: 编辑
     * @param {Object} row
     * @return {void}
     */    
    edit (row) {
      this.$router.push({
        name: 'landManageForm',
        query: {
          id: row.id,
          type: 'edit'
        }
      })
    },
    // 查看
    check (row) {
      this.$router.push({
        name: 'landManageForm',
        query: {
          id: row.id,
          type: 'check'
        }
      })
    }, 
    async del (row) {
      let isOk = await this.$confirm('确认删除该土地信息？')
      isOk && this.handleDeleteLandInfo(row.id)
    },

    // 删除
    async handleDeleteLandInfo (id) {
      const res = await this.$axios.post(`${deleteLand}?id=${id}`)
      if (res.status === 100) {
        this.$message.success('删除成功！')
        this.$refs.list.searchData()
      }
    },
    /**
     * @description: 导入
     * @param {*}
     * @return {*}
     */    
    landImport () {
      this.$router.push({
        name: 'landManageImport',
      })
    }
  }
}
</script>
<style lang="scss" scoped></style>
